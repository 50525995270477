import React from 'react'

const ContactForm = () => {
  const services = [
    'PowerHour (1u/week)',
    'DoublePower (2u/week)',
    'TriplePower (3u/week)',
    '1 trainingsessie',
  ]
  return (
    <form
      action='https://formspree.io/f/xayvpdrz'
      method='POST'
      className='form contact-form'
    >
      <div className='form-row'>
        <h5>Geef me een seintje.</h5>
        <label htmlFor='contact-name'>
          Je naam
          <input type='text' name='contact-name' id='contact-name' required />
        </label>
      </div>
      <div className='form-row'>
        <label htmlFor='contact-email'>
          Je email of telefoon
          <input type='text' name='contact-email' id='contact-email' required />
        </label>
      </div>
      <div className='form-row'>
        <label htmlFor='contact-message'>
          Jouw bericht
          <textarea name='contact-message' id='contact-message' />
        </label>
      </div>
      <div className='form-row'>
        <label htmlFor='interests'>Ik heb interesse in:</label>
        <checkboxGroup name='interests' id='contact-interests'>
          {services.map((item, index) => (
            <div key={index}>
              <label htmlFor={`cb${index}`}>
                <input
                  value={item}
                  type='checkbox'
                  name={`cb${index}`}
                  id={`cb${index}`}
                />
                {item}
              </label>
            </div>
          ))}
        </checkboxGroup>
        <em className='text-small'>
          je hebt altijd recht op een voordelige Try-Out
        </em>
      </div>
      <div className='form-row'>
        <button type='submit' className='btn hipster block'>
          Let's do this
        </button>
      </div>
    </form>
  )
}

export default ContactForm
