import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import ContactForm from '../components/ContactForm'
import ArticlesList from '../components/ArticlesList'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'

export const query = graphql`
  {
    mytab: contentfulTabDescription(tabname: { eq: "Contact" }) {
      description {
        childMarkdownRemark {
          html
        }
      }
      tabname
      title
    }

    article: allContentfulArticle(
      sort: { fields: order, order: DESC }
      filter: { featured: { eq: true } }
    ) {
      nodes {
        featured
        createdWhen
        createdBy
        id
        order
        tags
        title
        image {
          gatsbyImageData
          title
        }
      }
    }
  }
`

export default function contact({
  data: {
    article: { nodes: articles },
  },
}) {
  return (
    <Layout>
      <SEO
        title='Contact'
        description='Contacteer Sandra Perdieus, jouw personal trainer in Blanden. Bespreek je fitnessdoelen, plan een sessie en start je reis naar kracht en welzijn.'
      />
      <main className='page'>
        <section className='contact-page'>
          <article className='contact-info'>
            <h3>Vragen of hulp nodig?</h3>
            <p>
              Ik probeer <strong>het meest duidelijk</strong> te zijn over mijn
              filosofie, mijn diensten en mijn sfeer op mijn website. Je kan me
              steeds een vraag stellen, ik antwoord jou zo snel mogelijk.
            </p>
            <address>
              <h5>mijn gegevens</h5>
              <p>
                <a
                  href='mailto:sandra@strongbysandra.be'
                  className='hipster-btn'
                >
                  <FaEnvelope />
                </a>
                sandra@strongbysandra.be
              </p>
              <p>
                <a href='tel:+32484973247'>
                  <FaPhone />
                </a>
                +32 484 97 32 47
              </p>
              <p>
                <a
                  href='http://maps.google.com/?q=3052 Blanden, Bierbeekstraat 104, laurieusfit'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaLocationArrow />
                </a>
                Bierbeekstraat 104 - Blanden
              </p>
            </address>
          </article>
          <article>
            <ContactForm />
          </article>
        </section>
        <section className='featured-recipes'>
          <h4>Kijk ook eens hier!</h4>
          <ArticlesList articles={articles}></ArticlesList>
        </section>
      </main>
    </Layout>
  )
}
